export const HOST = process.env.REACT_APP_LOCAL==="true"?'Local_Application':'WhizPad_Application';

export const AWS_IOT_ATTACH_POLICY = '/iot/attach_policy'
export const CONFIG_PAD_LIST = '/config/pad-list';
export const CONFIG_PADS = '/config/pads'
export const CONFIG_ARRANGE_SHIFT = '/config/arrange_shift'
export const DATA_USER = '/data/user'
export const DATA_PADS = '/data/pads'
export const DATA_PADS_STAGE_HISTORY = '/data/pads_stage_history'
export const DATA_PADS_STAGE_CLUSTER = '/data/pads_stage_cluster'
export const DATA_PADS_WEEK_STAGE_HISTORY = '/data/pads_week_stage_history'
export const DATA_PADS_SLEEP_HISTORY = '/data/pads_sleep_history'
export const DATA_PADS_ACTIVITY_HISTORY = '/data/pads_activity_history'
export const DATA_NOTIFICATIONS = '/data/notifications'
export const DATA_NOTIFICATIONS_RECORD = '/data/notifications_record'
export const DATA_NOTIFICATIONS_HISTORY = '/data/notifications_history'
export const LOADING_API='/Loading';
export const HISTORY_DATA='/GetHistoryData';
export const HISTORY_ACT='/GetHistoryAct';