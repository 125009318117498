import * as Rx from 'rxjs';

import * as ApiGateway from '../api_gateway';
import { Auth, API } from 'aws-amplify';

let tempUser = undefined

export function isLoggedIn() {
    return Rx.Observable.create(subscriber => {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
            .then(user => {
                console.log(user);
                subscriber.next(true);
                subscriber.complete();
            })
            .catch(err => {
                console.log(err);
                subscriber.next(false);
                subscriber.complete();
            });
    });
}

export function checkAccountExist(email) {
    return Rx.Observable.create(subscriber => {
        Auth.signIn(email, '123')
            .then(res => {
                subscriber.next(true);
                subscriber.complete();
            })
            .catch(error => {
                const code = error.code;
                switch (code) {
                    case 'UserNotFoundException':
                        subscriber.next(false);
                        break;
                    default:
                        subscriber.next(true);
                        break;
                }
                subscriber.complete();
            });
    });
}

export function checkPwd(pwd) {
    return Rx.Observable.create(subscriber => {
        subscriber.next(pwd.length >= 8);
        subscriber.complete();
    });
}

export function signIn(email, pwd) {
    return Rx.Observable.create(async subscriber => {
        try {
            const user = await Auth.signIn(email, pwd);
            tempUser = user
            if (user.challengeName === 'SMS_MFA' ||
                user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                // in this case, we didn't ask for MFA
                subscriber.next({ result: false, code: 'SOFTWARE_TOKEN_MFA' });
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                // todo...
                // requires user to create a new password;
                subscriber.next({ result: false, code: 'NEW_PASSWORD_REQUIRED' });
            } else if (user.challengeName === 'MFA_SETUP') {
                // in this case, we didn't ask for MFA
                subscriber.next({ result: false, code: 'MFA_SETUP' });
            } else {
                // The user directly signs in
                console.log('sign in success');
                console.log(user);
                subscriber.next({ result: true, code: 'Success' });
            }
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
            } else if (err.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
            } else if (err.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
            } else if (err.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
            } else {
                console.log(err);
            }
            subscriber.next({ result: false, code: err.code });
        }
        subscriber.complete();
    });
}

export function signOut() {
    return Rx.Observable.create(async subscriber => {
        await Auth.signOut();
        subscriber.next(true);
        subscriber.complete();
    });
}

export function changeNewPwd(name, pwd) {
    return Rx.Observable.create(async subscriber => {
        try {
            const user = await Auth.completeNewPassword(tempUser, pwd, { name: name })
            console.log(`change new pwd =>`)
            console.log(user)
            subscriber.next({ result: true });
            subscriber.complete();
        } catch (e) {
            console.log(e)
            subscriber.error(e)
        }
    })
}

export function refreshSession() {
    return Rx.Observable.create(async subscriber => {
        try {
            const session = await Auth.currentSession();
            console.log('refresh session: ');
            console.log(session);
            subscriber.next(session);
            subscriber.complete();
        } catch (e) {
            console.log('refresh session error: ');
            console.log(e);
            subscriber.error(e);
        }
    });
}

export function currentCredentials() {
    return Rx.Observable.create(async subscriber => {
        try {
            const credentials = await Auth.currentCredentials();
            console.log('current credentials: ');
            console.log(credentials);
            subscriber.next(credentials);
            subscriber.complete();
        } catch (e) {
            console.log('current credentials error: ');
            console.log(e);
            subscriber.error(e);
        }
    });
}

export function attachAwsIotPolicy(identityId) {
    return Rx.Observable.create(async subscriber => {
        try {
            // let session = (await Auth.currentSession());
            // console.log(session);
            let params = {
                body: { 'identityId': identityId },
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `${session.getAccessToken().getJwtToken()}`,
                }
            }
            let response = await API.post(ApiGateway.HOST, ApiGateway.AWS_IOT_ATTACH_POLICY, params);
            console.log('attach aws iot policy success:');
            console.log(response);
            subscriber.next(true);
        } catch (e) {
            console.log('attach aws iot policy with error:');
            console.log(e);
            subscriber.next(false);
        }
        subscriber.complete();
    });
}