import * as LocalServer from "./local/pad_api"
import * as AWSServer from './aws/pad_api'
export function padsInfo() {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.padsInfo() : AWSServer.padsInfo();
}

export function notifications() {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.notifications() : AWSServer.notifications();
}

export function notificationsRecord() {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.notificationsRecord() : AWSServer.notificationsRecord();
}

export function notificationsHistory(deviceId, date) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.notificationsHistory(deviceId, date) : AWSServer.notificationsHistory(deviceId, date);
}

export function postureHistory(deviceId, date) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.postureHistory(deviceId, date) : AWSServer.postureHistory(deviceId, date);
}

export function stageCkuster(deviceId, date) {
    return process.env.REACT_APP_LOCAL === "true" ? undefined : AWSServer.stageCkuster(deviceId, date);
}

/**
 * Two Week Model
 * @param {string} deviceId 
 * @param {string} date 
 * @return {object} ranege, stage, percentages, correlation, display
 */
export function weekPostureHistory(deviceId, date) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.weekPostureHistory(deviceId, date) : AWSServer.weekPostureHistory(deviceId, date);
}

export function activityHistory(deviceId, date) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.activityHistory(deviceId, date) : AWSServer.activityHistory(deviceId, date);
}

export function sleepHistory(deviceId, date) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.sleepHistory(deviceId, date) : AWSServer.sleepHistory(deviceId, date);
}

function isToday(dateStr) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.isToday(dateStr) : AWSServer.isToday(dateStr);
}