import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import intl from 'react-intl-universal'
import Lottie from 'react-lottie';

import { MainContext } from '../../../utils/context_utils';
import HistoryBloc, { HistoryPageId } from '../../../bloc/history/history_bloc';

import * as Dimens from '../../../styles/dimens';
import * as Colors from '../../../styles/colors';
import Row from '../../../components/layout/row';
import Column from '../../../components/layout/column';
import Stack from '../../../components/layout/stack';

import BlocBuilder from 'bloc-builder-react';
import { ButtonBase, Paper, IconButton } from '@material-ui/core';
import DatePickerDialog from '../../../dialog/date_picker_dialog';
import PadChart from '../../../components/widgets/chart/pad_chart';
import ChartLegend from '../../../components/widgets/chart/chart_legend';
import IcArrowLeft from '@material-ui/icons/ArrowBackIosRounded';
import IcArrowRight from '@material-ui/icons/ArrowForwardIosRounded';
import { ReactComponent as IcArrow } from '../../../assets/ic_arrow.svg'
import IcEvent from '@material-ui/icons/EventRounded';
import { Scrollbars } from 'react-custom-scrollbars';
import AnimLoading from '../../../assets/lottie/textfield_loading.json';

const printf = require('printf');

export default function HistoryPage(props) {

    const _mainBloc = useContext(MainContext);
    // const isWeb = useMediaQuery('(min-width: 768px)');
    const isWeb = useMediaQuery('(min-width: 1200px)');
    const css = useStyles(props);

    let _bloc = useRef(new HistoryBloc());
    const [init, setInit] = useState(false);
    let isTodayGlobal = true;

    useEffect(() => {
        console.log(`history page init`);
        const bloc = _bloc.current;
        bloc.init();
        setInit(true);
        isPushFromHomePage()
        return () => {
            setInit(false);
            bloc.dispose();
        }
    }, [])

    const isPushFromHomePage = () => {
        //如果state有值
        if (props.location.state) {
            console.log('findindex', props.location.state)
            _bloc.current.findIndex(props.location.state.deviceId);
        }
    }

    const onPostureChartSelected = (selected, index) => {
        try {
            if (selected) {
                console.log(`posture chart selected: ${index}`);
                _bloc.current.postureChartLabel.next(_bloc.current.chartData.getValue().data.posture.label[index]);
            } else {
                _bloc.current.postureChartLabel.next({});
            }
        } catch (e) {

        }
    };

    const onActivityChartSelected = (selected, index) => {
        if (selected) {
            console.log(`activity chart selected: ${index}`);
            _bloc.current.activityChartLabel.next(_bloc.current.chartData.getValue().data.activity.label[index]);
        } else {
            _bloc.current.activityChartLabel.next({});
        }
    };

    const onSleepChartSelected = (selected, index) => {
        if (selected) {
            console.log(`sleep chart selected: ${index}`);
            _bloc.current.sleepChartLabel.next(_bloc.current.chartData.getValue().data.sleep.label[index]);
        } else {
            _bloc.current.sleepChartLabel.next({});
        }
    }

    const getClusterText = (cluster) => {
        if (cluster === 0) return "自由型";
        if (cluster === 1) return "規律型";
        if (cluster === 2) return "久臥型";
        if (cluster === 3) return "離家型";
    }

    return (!init ? null :
        <BlocBuilder
            initialValue={_bloc.current.configLoading.getValue()}
            subject={_bloc.current.configLoading}
            builder={(snapshot) => {
                const configLoading = snapshot.data;
                if (configLoading) return null;
                return (
                    <Row width='100%' height='100%' >
                        {/* 裝置列表 */}
                        <Column width={`${isWeb ? Dimens.historyDeviceItemWidthL : Dimens.historyDeviceItemWidth}px`} height='100%'
                            backgroundColor='#ffffff'
                            className={css.RootDeviceList}>
                            <BlocBuilder
                                initialValue={_bloc.current.configs.getValue()}
                                subject={_bloc.current.configs}
                                builder={(snapshot) => {
                                    const configs = snapshot.data;
                                    return (
                                        <Scrollbars
                                            autoHide
                                            style={{
                                                width: `${isWeb ? Dimens.historyDeviceItemWidthL : Dimens.historyDeviceItemWidth}px`,
                                                backgroundColor: '#ffffff',
                                                marginBottom: isWeb ? `${Dimens.appbarSizeL}px` : `${Dimens.appbarSize}px`,
                                                // overflowY: 'scroll'
                                            }}>
                                            {
                                                configs.map(function (item, index, arr) {
                                                    // console.log('debug',item.deviceId)
                                                    return (
                                                        <ButtonBase
                                                            className={css.DeviceListItem}
                                                            key={item.deviceId}
                                                            onClick={() => {
                                                                _bloc.current.btnDevicesClicked(index)
                                                            }}>
                                                            <BlocBuilder
                                                                initialValue={_bloc.current.selectedData.getValue()}
                                                                subject={_bloc.current.selectedData}
                                                                builder={(snapshot) => {
                                                                    const selectedIndex = snapshot.data.index;
                                                                    const isSelected = selectedIndex === index;
                                                                    return (
                                                                        <Stack width='100%' height={`${Dimens.historyDeviceItem}px`} >
                                                                            <Column width='100%' height='90%'
                                                                                className={css.DeviceListItemContent}
                                                                                style={{ backgroundColor: isSelected ? '#f0f0f0' : 'transparent' }}>
                                                                                <span className={css.DeviceListName}>{item.name}</span>
                                                                                <span className={css.DeviceListId}>{item.macAddress}</span>
                                                                            </Column>
                                                                            <div className={css.DeviceListIndicator}
                                                                                style={{ backgroundColor: isSelected ? Colors.accent : 'transparent' }} />
                                                                        </Stack>
                                                                    );
                                                                }} />
                                                        </ButtonBase>
                                                    );
                                                })
                                            }
                                        </Scrollbars>
                                    );
                                }} />
                        </Column>
                        <div className={css.RootDivider} />
                        {/* 繪圖區 */}
                        <Column width='auto' height='100%' className={css.RootCharts}>
                            <Row width='100%' height={`${Dimens.historyChartHeaderBar}px`} backgroundColor='#ffffff' >
                                <BlocBuilder
                                    initialValue={_bloc.current.selectedData.getValue()}
                                    subject={_bloc.current.selectedData}
                                    builder={(snapshot) => {
                                        const selectedIndex = snapshot.data.index;
                                        const date = snapshot.data.date;
                                        console.log('day', _bloc.current.today)
                                        const isToday = moment(date).isSame(moment(_bloc.current.today), 'days');
                                        isTodayGlobal = isToday;
                                        const configs = _bloc.current.configs.getValue()
                                        const roomName = configs.length <= 0 ? '' : configs[selectedIndex].name
                                        return (
                                            <Row width='auto' height='100%' className={css.ChartHeaderBar} >
                                                {/* Header -> 選擇日期 */}
                                                <span className={css.ChartHeaderBarTitle} >{configs[selectedIndex] ? `${intl.get('room')} : ${configs[selectedIndex].name}` : intl.get('page_history_notify_add_bed')}</span>
                                                <Row width='auto' height='auto' style={{ alignItems: 'center' }} >
                                                    <SvgIconButton onClick={() => { _bloc.current.btnClicked.next(HistoryPageId.BtnDateBackward) }} ><IcArrowLeft className={css.DateIcBtn} /></SvgIconButton>
                                                    <span className={css.TextDesc} style={{ marginLeft: `${Dimens.marginL}px`, marginRight: `${Dimens.marginM}px` }} >
                                                        <span className={css.TextNum}>{date.getFullYear()}</span>&nbsp;{intl.get('format_year')}&nbsp;
                                                        <span className={css.TextNum}>{date.getMonth() + 1}</span>&nbsp;{intl.get('format_month')}&nbsp;
                                                        <span className={css.TextNum}>{date.getDate()}</span>&nbsp;{intl.get('format_day')}
                                                    </span>
                                                    <SvgIconButton style={{ marginRight: `${Dimens.marginL}px` }}
                                                        onClick={() => { _bloc.current.btnClicked.next(HistoryPageId.BtnDatePicker) }}>
                                                        <IcEvent className={css.DateIcBtn} />
                                                    </SvgIconButton>
                                                    <SvgIconButton
                                                        style={{ visibility: isToday ? 'hidden' : 'visible' }}
                                                        onClick={() => { _bloc.current.btnClicked.next(HistoryPageId.BtnDateForward) }} >
                                                        <IcArrowRight className={css.DateIcBtn} />
                                                    </SvgIconButton>
                                                </Row>
                                            </Row>
                                        );
                                    }} />
                                <div style={{ width: '1px', height: '100%', backgroundColor: Colors.dividerLight }} />
                            </Row>
                            <div className={css.ChartHeaderBarDivider} />
                            <BlocBuilder
                                initialValue={_bloc.current.chartData.getValue()}
                                subject={_bloc.current.chartData}
                                builder={(snapshot) => {
                                    const { loading, data } = snapshot.data;
                                    if (loading || !data.hasOwnProperty('posture')) return null;
                                    const { posture, activity, sleep, statistics, weekPosture, cluster } = data;
                                    console.log('paddata', data)
                                    const postureData = {
                                        datasets: [
                                            {
                                                fillColor: "rgba(220,220,220,0.5)",
                                                strokeColor: "rgba(220,220,220,0.8)",
                                                highlightFill: "rgba(220,220,220,0.7)",
                                                highlightStroke: "rgba(220,220,220,1)",
                                                data: posture.data,
                                                backgroundColor: posture.color,
                                                weights: posture.weight,
                                                hover: "false",
                                            },
                                        ],
                                        labels: posture.label,
                                    };
                                    const activityData = {
                                        datasets: [
                                            {
                                                fillColor: "rgba(220,220,220,0.5)",
                                                strokeColor: "rgba(220,220,220,0.8)",
                                                highlightFill: "rgba(220,220,220,0.7)",
                                                highlightStroke: "rgba(220,220,220,1)",
                                                data: activity.data,
                                                backgroundColor: activity.color,
                                                weights: activity.weight,
                                            },
                                        ],
                                        labels: activity.label,
                                    };
                                    const sleepData = {
                                        datasets: [
                                            {
                                                fillColor: "rgba(220,220,220,0.5)",
                                                strokeColor: "rgba(220,220,220,0.8)",
                                                highlightFill: "rgba(220,220,220,0.7)",
                                                highlightStroke: "rgba(220,220,220,1)",
                                                data: sleep.data,
                                                backgroundColor: sleep.color,
                                                weights: sleep.weight,
                                            },
                                        ],
                                        labels: sleep.label,
                                    };

                                    const weekPostureData = {
                                        datasets: [
                                            {
                                                fillColor: "rgba(220,220,220,0.5)",
                                                strokeColor: "rgba(220,220,220,0.8)",
                                                highlightFill: "rgba(220,220,220,0.7)",
                                                highlightStroke: "rgba(220,220,220,1)",
                                                data: weekPosture.data,
                                                backgroundColor: weekPosture.color,
                                                weights: weekPosture.weight,
                                            },
                                        ],
                                        labels: weekPosture.label,
                                    };

                                    const { stageTime, sleepTime } = statistics;
                                    const { all, day, night } = stageTime;
                                    const statisticsData = {
                                        all: { hr: parseInt(all.inBed / 60), min: all.inBed % 60 },
                                        day: { hr: parseInt(day.inBed / 60), min: day.inBed % 60 },
                                        night: { hr: parseInt(night.inBed / 60), min: night.inBed % 60 },
                                        sleep_all: { hr: parseInt(sleepTime.all / 60), min: sleepTime.all % 60 },
                                        sleep_day: { hr: parseInt(sleepTime.day / 60), min: sleepTime.day % 60 },
                                        sleep_night: { hr: parseInt(sleepTime.night / 60), min: sleepTime.night % 60 },
                                    }
                                    console.log('paddata', statisticsData)
                                    let alertAllColor = Colors.textBDark, alertDayColor = Colors.textBDark, alertNightColor = Colors.textBDark;
                                    // if (!isTodayGlobal) {
                                    //     alertAllColor = statisticsData.all.hr >= 12 ? alertAllColor = Colors.textError : alertAllColor = Colors.textBDark;
                                    //     alertDayColor = statisticsData.day.hr >= 6 ? alertDayColor = Colors.textError : alertDayColor = Colors.textBDark;
                                    //     alertNightColor = statisticsData.night.hr < 6 ? alertNightColor = Colors.textError : alertNightColor = Colors.textBDark;
                                    // }

                                    let alertWeekAllColor = Colors.textBDark, alertWeekDayColor = Colors.textBDark, alertWeekNightColor = Colors.textBDark;
                                    // alertWeekAllColor = weekPosture.statistic.all.hour >= 12 ? alertWeekAllColor = Colors.textError : alertWeekAllColor = Colors.textBDark;
                                    // alertWeekDayColor = weekPosture.statistic.day.hour >= 6 ? alertWeekDayColor = Colors.textError : alertWeekDayColor = Colors.textBDark;
                                    // alertWeekNightColor = weekPosture.statistic.night.hour < 6 ? alertWeekNightColor = Colors.textError : alertWeekNightColor = Colors.textBDark;

                                    const [daySubModel, allSubModel, nightSubModel] = [
                                        dayMinusWeek(weekPosture.statistic.day.hour, weekPosture.statistic.day.min, day.inBed),
                                        dayMinusWeek(weekPosture.statistic.all.hour, weekPosture.statistic.all.min, all.inBed),
                                        dayMinusWeek(weekPosture.statistic.night.hour, weekPosture.statistic.night.min, night.inBed),
                                    ]

                                    return (
                                        <Scrollbars autoHide style={{ width: '100%', height: `calc(100% - ${Dimens.historyChartHeaderBar + Dimens.divider}px)` }}>
                                            <Column width='100%' height='auto' style={{ marginBottom: `${(isWeb ? Dimens.appbarSizeL : Dimens.appbarSize) + Dimens.marginL}px` }}>
                                                <Row width='100%' height={`${Dimens.historyChartItemHeader}px`} className={css.ChartItemHeader} >
                                                    <span className={css.ChartItemHeaderTitle} >{intl.get('page_history_chart_posture_title')}</span>
                                                    <Row width='auto' height='100%' className={css.ChartItemHeaderLegendLayout} >
                                                        <ChartLegend color={Colors.historyPostureLie} text={intl.get('page_history_chart_posture_legend_on_bed')} className={css.ChartItemHeaderLegend} />
                                                        <ChartLegend color={Colors.historyPostureLeave} text={intl.get('page_history_chart_posture_legend_off_bed')} className={css.ChartItemHeaderLegend} />
                                                        <ChartLegend color={Colors.primary} text={intl.get('page_history_chart_posture_legend_offline')} className={css.ChartItemHeaderLegend} />
                                                    </Row>
                                                </Row>
                                                {isWeb ? (
                                                    <Row width='100%' height={`30px`} style={{ paddingLeft: '5%', paddingRight: '5%', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Row className={css.ChartItemHeaderDetail}>
                                                            <div className={css.TextDesc} style={{ color: alertDayColor }}>
                                                                {intl.get('page_history_chart_bed_day')}&nbsp;:&nbsp;
                                                                <span className={css.TextStageNum}>{statisticsData.day.hr}</span>&nbsp;
                                                                {intl.get('hour')}&nbsp;<span className={css.TextStageNum}>{statisticsData.day.min}</span>&nbsp;
                                                                {intl.get('minute')}
                                                            </div>
                                                            {
                                                                isTodayGlobal === false ?
                                                                    daySubModel.positive !== null ?
                                                                        <ArrowIconHour positive={daySubModel.positive} hour={daySubModel.hour} color={daySubModel.color} />
                                                                        : null
                                                                    : null
                                                            }
                                                            {/* {process.env.REACT_APP_LOCAL !== 'true' ?
                                                                isTodayGlobal === false ?
                                                                    daySubModel.positive !== null ?
                                                                        <ArrowIconHour positive={daySubModel.positive} hour={daySubModel.hour} color={daySubModel.color} />
                                                                        : null
                                                                    : null
                                                                : null
                                                            } */}

                                                        </Row>
                                                        <Row className={css.ChartItemHeaderDetail}>
                                                            <div className={css.TextDesc} style={{ color: alertAllColor }}>
                                                                {intl.get('page_history_chart_bed_allday')}&nbsp;:&nbsp;
                                                                <span className={css.TextStageNum}>{statisticsData.all.hr}</span>&nbsp;
                                                                {intl.get('hour')}&nbsp;<span className={css.TextStageNum}>{statisticsData.all.min}</span>&nbsp;
                                                                {intl.get('minute')}
                                                            </div>
                                                            {
                                                                isTodayGlobal === false ?
                                                                    allSubModel.positive !== null ?
                                                                        <ArrowIconHour positive={allSubModel.positive} hour={allSubModel.hour} color={allSubModel.color} />
                                                                        : null
                                                                    : null
                                                            }
                                                            {/* {process.env.REACT_APP_LOCAL !== 'true' ?
                                                                isTodayGlobal === false ?
                                                                    allSubModel.positive !== null ?
                                                                        <ArrowIconHour positive={allSubModel.positive} hour={allSubModel.hour} color={allSubModel.color} />
                                                                        : null
                                                                    : null
                                                                : null
                                                            } */}
                                                        </Row>
                                                        <Row className={css.ChartItemHeaderDetail}>
                                                            <div className={css.TextDesc} style={{ color: alertNightColor }}>
                                                                {intl.get('page_history_chart_bed_night')}&nbsp;:&nbsp;
                                                                <span className={css.TextStageNum}>{statisticsData.night.hr}</span>&nbsp;
                                                                {intl.get('hour')}&nbsp;<span className={css.TextStageNum}>{statisticsData.night.min}</span>&nbsp;
                                                                {intl.get('minute')}
                                                            </div>
                                                            {
                                                                isTodayGlobal === false ?
                                                                    nightSubModel.positive !== null ?
                                                                        <ArrowIconHour positive={nightSubModel.positive} hour={nightSubModel.hour} color={nightSubModel.color} />
                                                                        : null
                                                                    : null
                                                            }
                                                            {/* {process.env.REACT_APP_LOCAL !== 'true' ?
                                                                isTodayGlobal === false ?
                                                                    nightSubModel.positive !== null ?
                                                                        <ArrowIconHour positive={nightSubModel.positive} hour={nightSubModel.hour} color={nightSubModel.color} />
                                                                        : null
                                                                    : null
                                                                : null
                                                            } */}
                                                        </Row>

                                                    </Row>
                                                ) : (<></>)}
                                                <Paper elevation={3} className={css.ChartItem} >
                                                    <Column width='100%' height='100%'>
                                                        <Row width='100%' height={`${Dimens.historyChartItemFooter}px`} className={css.ChartLabelLayout}>
                                                            <BlocBuilder
                                                                initialValue={_bloc.current.postureChartLabel.getValue()}
                                                                subject={_bloc.current.postureChartLabel}
                                                                builder={(snapshot) => {
                                                                    if (!snapshot.data.hasOwnProperty('state')) {
                                                                        return null;
                                                                    }
                                                                    const { from, to, state } = snapshot.data;
                                                                    return (
                                                                        <span style={{ position: 'absolute' }} className={css.TextDesc} >
                                                                            <span className={css.TextNum}>{printf('%02d', [Math.floor(from / 60)])}</span>&nbsp;{intl.get('page_history_chart_hint_format_hour')}&nbsp;<span className={css.TextNum}>{printf('%02d', [from % 60])}</span>&nbsp;{intl.get('page_history_chart_hint_format_min')}
                                                                            &nbsp;{intl.get('date_until')}&nbsp;
                                                                            <span className={css.TextNum}>{printf('%02d', [Math.floor(to / 60)])}</span>&nbsp;{intl.get('page_history_chart_hint_format_hour')}&nbsp;<span className={css.TextNum}>{printf('%02d', [to % 60])}</span>&nbsp;{intl.get('page_history_chart_hint_format_min')}&nbsp;&nbsp;
                                                                            <span className={css.TextTitle}>{state}</span>
                                                                        </span>
                                                                    );
                                                                }} />

                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: Dimens.marginL }}>
                                                                {/* <div hidden={!isWeb} className={css.TextStatistics}>{`相關係數 : ${weekPosture.correlation}`}</div> */}
                                                                <div hidden={!isWeb} className={css.TextStatistics}>{getClusterText(cluster)}</div>
                                                            </div>

                                                        </Row>
                                                        <Row width='100%' height='auto' className={css.Chart} style={{ paddingLeft: '16px' }}>
                                                            <PadChart
                                                                name='posture'
                                                                onSelected={onPostureChartSelected}
                                                                refresh
                                                                data={postureData}
                                                                max={3}
                                                                min={0} />
                                                        </Row>
                                                    </Column>
                                                </Paper>
                                                {/* 常模 */}
                                                <Row width='100%' height={`${Dimens.historyChartItemHeader}px`} className={css.ChartItemHeader} >
                                                    <Row width='100%' className={css.ChartWeekHeaderTitle}>
                                                        <span>{`兩週常模 (${weekPosture.range.from}~${weekPosture.range.to})`}</span>
                                                        <span>常模分數：{weekPosture.points !== 0 ? `${weekPosture.points}分` : '資料不足'}</span>
                                                        {/* <span>{`常模分數：${weekPosture.points}`}</span> */}
                                                    </Row>
                                                </Row>
                                                {/* {isWeb ? (
                                                    <Row width='100%' height={`30px`} style={{ paddingLeft: '5%', paddingRight: '5%', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <div className={css.TextStatistics}>{`白天平均臥床比 : ${weekPosture.percentages.day}%`}</div>
                                                        <div className={css.TextStatistics}>{`全天平均臥床比 : ${weekPosture.percentages.all}%`}</div>
                                                        <div className={css.TextStatistics}>{`晚上平均臥床比 : ${weekPosture.percentages.night}%`}</div>
                                                    </Row>
                                                ) : (<></>)} */}
                                                <Paper elevation={3} className={css.ChartItem} >
                                                    <Column width='100%' height='100%'>
                                                        {isWeb ?
                                                            <Row width='100%' height={`${Dimens.historyChartItemFooter}px`} className={css.ChartWeekLabelLayout}>
                                                                <div style={{ color: alertWeekDayColor }} className={css.TextDesc}>白天平均臥床 :&nbsp;<span className={css.TextStageNum}>{weekPosture.statistic.day.hour}</span>&nbsp;{intl.get('page_history_chart_hint_format_hour')}&nbsp;<span className={css.TextStageNum}>{weekPosture.statistic.day.min}</span>{intl.get('page_history_chart_hint_format_min')}</div>
                                                                <div style={{ color: alertWeekAllColor }} className={css.TextDesc}>全天平均臥床 :&nbsp;<span className={css.TextStageNum}>{weekPosture.statistic.all.hour}</span>&nbsp;{intl.get('page_history_chart_hint_format_hour')}&nbsp;<span className={css.TextStageNum}>{weekPosture.statistic.all.min}</span>{intl.get('page_history_chart_hint_format_min')}</div>
                                                                <div style={{ color: alertWeekNightColor }} className={css.TextDesc}>晚上平均臥床 :&nbsp;<span className={css.TextStageNum}>{weekPosture.statistic.night.hour}</span>&nbsp;{intl.get('page_history_chart_hint_format_hour')}&nbsp;<span className={css.TextStageNum}>{weekPosture.statistic.night.min}</span>{intl.get('page_history_chart_hint_format_min')}</div>
                                                            </Row>
                                                            : <Row width='100%' height={`${Dimens.historyChartItemFooter}px`}></Row>}
                                                        <Row width='100%' height='auto' className={css.Chart}>
                                                            <PadChart
                                                                name='weekPosture'
                                                                data={weekPostureData}
                                                                max={100}
                                                                min={0} />
                                                        </Row>
                                                    </Column>
                                                </Paper>

                                                {/* 活動量 */}
                                                <Row width='100%' height={`${Dimens.historyChartItemHeader}px`} className={css.ChartItemHeader} >
                                                    <span className={css.ChartItemHeaderTitle} >{intl.get('page_history_chart_activity_title')}</span>
                                                </Row>
                                                <Paper elevation={3} className={css.ChartItem} >
                                                    <Column width='100%' height='100%'>
                                                        <Row width='100%' height={`${Dimens.historyChartItemFooter}px`} className={css.ChartLabelLayout}>
                                                            <BlocBuilder
                                                                initialValue={_bloc.current.activityChartLabel.getValue()}
                                                                subject={_bloc.current.activityChartLabel}
                                                                builder={(snapshot) => {
                                                                    if (!snapshot.data.hasOwnProperty('activity')) {
                                                                        return null;
                                                                    }
                                                                    const { from, to, activity } = snapshot.data;
                                                                    return (
                                                                        <span className={css.TextDesc} >
                                                                            <span className={css.TextNum}>{printf('%02d', [Math.floor(from / 60)])}</span>&nbsp;{intl.get('page_history_chart_hint_format_hour')}&nbsp;<span className={css.TextNum}>{printf('%02d', [from % 60])}</span>&nbsp;{intl.get('page_history_chart_hint_format_min')}
                                                                            &nbsp;{intl.get('date_until')}&nbsp;
                                                                            <span className={css.TextNum}>{printf('%02d', [Math.floor(to / 60)])}</span>&nbsp;{intl.get('page_history_chart_hint_format_hour')}&nbsp;<span className={css.TextNum}>{printf('%02d', [to % 60])}</span>&nbsp;{intl.get('page_history_chart_hint_format_min')}&nbsp;&nbsp;
                                                                            {intl.get('page_history_chart_activity_legend')}：&nbsp;<span className={css.TextNum}>{activity}</span>&nbsp;%
                                                                        </span>
                                                                    );
                                                                }} />
                                                        </Row>
                                                        <Row width='100%' height='auto' className={css.Chart}>
                                                            <PadChart
                                                                name='activity'
                                                                onSelected={onActivityChartSelected}
                                                                data={activityData} />
                                                        </Row>
                                                    </Column>
                                                </Paper>
                                                {/* 睡眠 */}
                                                <Row width='100%' height={`${Dimens.historyChartItemHeader}px`} className={css.ChartItemHeader} >
                                                    <span className={css.ChartItemHeaderTitle} >{intl.get('page_history_chart_sleep_title')}</span>
                                                    <Row width='auto' height='100%' className={css.ChartItemHeaderLegendLayout} >
                                                        {/* <ChartLegend color={Colors.historySleepDeep} text={intl.get('page_history_chart_sleep_legend_deep')} className={css.ChartItemHeaderLegend} /> */}
                                                        <ChartLegend color={Colors.historySleepLight} text={intl.get('page_history_chart_sleep_legend_light')} className={css.ChartItemHeaderLegend} />
                                                        <ChartLegend color={Colors.historySleepAwake} text={intl.get('page_history_chart_sleep_legend_awake')} className={css.ChartItemHeaderLegend} />
                                                        <ChartLegend color={Colors.historySleepLeave} text={intl.get('page_history_chart_posture_legend_off_bed')} className={css.ChartItemHeaderLegend} />
                                                    </Row>
                                                </Row>
                                                {isWeb ? (
                                                    <Row width='100%' height={`30px`} style={{ paddingLeft: '5%', paddingRight: '5%', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Row className={css.ChartItemHeaderDetail}>
                                                            <div className={css.TextDesc} style={{ color: alertDayColor }}>
                                                                {intl.get('page_history_chart_sleep_day')}&nbsp;:&nbsp;
                                                                <span className={css.TextStageNum}>{statisticsData.sleep_day.hr}</span>&nbsp;
                                                                {intl.get('hour')}&nbsp;<span className={css.TextStageNum}>{statisticsData.sleep_day.min}</span>&nbsp;
                                                                {intl.get('minute')}
                                                            </div>
                                                        </Row>
                                                        <Row className={css.ChartItemHeaderDetail}>
                                                            <div className={css.TextDesc} style={{ color: alertAllColor }}>
                                                                {intl.get('page_history_chart_sleep_allday')}&nbsp;:&nbsp;
                                                                <span className={css.TextStageNum}>{statisticsData.sleep_all.hr}</span>&nbsp;
                                                                {intl.get('hour')}&nbsp;<span className={css.TextStageNum}>{statisticsData.sleep_all.min}</span>&nbsp;
                                                                {intl.get('minute')}
                                                            </div>
                                                        </Row>
                                                        <Row className={css.ChartItemHeaderDetail}>
                                                            <div className={css.TextDesc} style={{ color: alertNightColor }}>
                                                                {intl.get('page_history_chart_sleep_night')}&nbsp;:&nbsp;
                                                                <span className={css.TextStageNum}>{statisticsData.sleep_night.hr}</span>&nbsp;
                                                                {intl.get('hour')}&nbsp;<span className={css.TextStageNum}>{statisticsData.sleep_night.min}</span>&nbsp;
                                                                {intl.get('minute')}
                                                            </div>
                                                        </Row>
                                                    </Row>
                                                ) : null}
                                                <Paper elevation={3} className={css.ChartItem} >
                                                    <Column width='100%' height='100%'>
                                                        <Row width='100%' height={`${Dimens.historyChartItemFooter}px`} className={css.ChartLabelLayout}>
                                                            <BlocBuilder
                                                                initialValue={_bloc.current.sleepChartLabel.getValue()}
                                                                subject={_bloc.current.sleepChartLabel}
                                                                builder={(snapshot) => {
                                                                    if (!snapshot.data.hasOwnProperty('state')) {
                                                                        return null;
                                                                    }
                                                                    const { from, to, state } = snapshot.data;
                                                                    return (
                                                                        <span className={css.TextDesc} >
                                                                            <span className={css.TextNum}>{printf('%02d', [Math.floor(from / 60)])}</span>&nbsp;{intl.get('page_history_chart_hint_format_hour')}&nbsp;<span className={css.TextNum}>{printf('%02d', [from % 60])}</span>&nbsp;{intl.get('page_history_chart_hint_format_min')}
                                                                            &nbsp;{intl.get('date_until')}&nbsp;
                                                                            <span className={css.TextNum}>{printf('%02d', [Math.floor(to / 60)])}</span>&nbsp;{intl.get('page_history_chart_hint_format_hour')}&nbsp;<span className={css.TextNum}>{printf('%02d', [to % 60])}</span>&nbsp;{intl.get('page_history_chart_hint_format_min')}&nbsp;&nbsp;
                                                                            {intl.get('page_history_chart_sleep_status')}：&nbsp;<span className={css.TextTitle}>{state}</span>
                                                                        </span>
                                                                    );
                                                                }} />
                                                        </Row>
                                                        <Row width='100%' height='auto' className={css.Chart} style={{ paddingLeft: '16px' }}>
                                                            <PadChart
                                                                name='sleep'
                                                                onSelected={onSleepChartSelected}
                                                                data={sleepData}
                                                                max={3}
                                                                min={0} />
                                                        </Row>
                                                    </Column>
                                                </Paper>

                                            </Column>
                                        </Scrollbars>
                                    );
                                }} />
                            <BlocBuilder
                                initialValue={_bloc.current.datePickerDialog.getValue()}
                                subject={_bloc.current.datePickerDialog}
                                builder={(snapshot) => {
                                    const { show, date } = snapshot.data;
                                    return (
                                        <DatePickerDialog open={show} date={date}
                                            onClose={() => { _bloc.current.datePickerDialog.next({ show: false, date: date }); }}
                                            onDateSelected={(date) => {
                                                const index = _bloc.current.selectedData.getValue().index;
                                                _bloc.current.selectedData.next({ index: index, date: date });
                                            }} />
                                    );
                                }} />
                            {/* 頁面讀取中 */}
                            <BlocBuilder
                                initialValue={_bloc.current.historyLoading.getValue()}
                                subject={_bloc.current.historyLoading}
                                builder={(snapshot) => {
                                    if (!snapshot.data) return null;
                                    return (
                                        <Column width='100%' height='100%' className={css.loadingLayout}>
                                            <Lottie
                                                isClickToPauseDisabled
                                                options={{
                                                    loop: true,
                                                    autoplay: true,
                                                    animationData: AnimLoading,
                                                    rendererSettings: {
                                                        preserveAspectRatio: 'xMidYMid slice'
                                                    }
                                                }}
                                                width={`${Dimens.padConfigLoading}px`}
                                                height='auto' />
                                            <span className={css.loadingText}>{intl.get('loading_data')}</span>
                                        </Column>
                                    );
                                }} />
                        </Column>
                    </Row>
                );
            }} />
    )
}

const useStyles = makeStyles({
    RootDeviceList: {
        // overflowY: 'scroll',
    },
    RootCharts: {
        flex: 1,
    },
    RootDivider: {
        width: `${Dimens.divider}px`,
        height: '100%',
        backgroundColor: Colors.dividerNormal,
    },
    DeviceListItem: {
        width: '100%',
        height: `${Dimens.historyDeviceItem}px`,
    },
    DeviceListItemContent: {
        position: 'absolute',
        top: '5%',
        left: '0px',
        justifyContent: 'center',
        paddingLeft: `${Dimens.marginM}px`,
        paddingRight: `${Dimens.marginM}px`,
    },
    DeviceListIndicator: {
        position: 'absolute',
        top: '5%',
        right: '0px',
        width: `${Dimens.historyDeviceItemIndicator}px`,
        height: '90%',
    },
    DeviceListName: {
        fontWeight: 'bold',
        fontSize: Dimens.textM,
        color: Colors.textBNormal,
    },
    DeviceListId: {
        fontSize: Dimens.textXS,
        color: Colors.textBXLight,
    },
    ChartHeaderBar: {
        flex: 1,
        alignItems: 'center',
        marginLeft: '5%',
        marginRight: '5%',
    },
    ChartHeaderBarTitle: {
        flex: 1,
        width: 'auto',
        height: 'auto',
        fontWeight: 'bold',
        fontSize: Dimens.textL,
        color: Colors.textBNormal,
    },
    DateIcBtn: {
        width: `${Dimens.historyChartHeaderBarIconBtn}px`,
        height: `${Dimens.historyChartHeaderBarIconBtn}px`,
        color: Colors.iconDefault,
    },
    ChartHeaderBarDivider: {
        width: '100%',
        height: `${Dimens.divider}px`,
        backgroundColor: Colors.dividerNormal,
    },
    ChartItemHeader: {
        paddingLeft: '5%',
        paddingRight: '5%',
        alignItems: 'center',
        marginTop: '20px'
    },
    ChartItemHeaderTitle: {
        fontWeight: 'bold',
        fontSize: Dimens.textL,
        color: Colors.primary,
    },
    ChartItemHeaderDetail: {
        display: 'flex',
        alignItems: 'center',
    },
    ChartWeekHeaderTitle: {
        justifyContent: 'space-between',
        fontWeight: 'bold',
        fontSize: Dimens.textL,
        color: Colors.primary,
    },
    ChartItemHeaderLegendLayout: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    ChartItemHeaderLegend: {
        marginLeft: `${Dimens.marginM}px`,
    },
    ChartItem: {
        width: '90%',
        height: `${Dimens.historyChartItem}px`,
        marginLeft: '5%',
        backgroundColor: '#ffffff',
        borderRadius: `${Dimens.historyChartItemCorner}px`,
        // overflowX: 'scroll',
    },
    Chart: {
        flex: 1,
    },
    ChartLabelLayout: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    ChartWeekLabelLayout: {
        // paddingRight: Dimens.marginL,
        padding: (0, Dimens.marginL, 0, Dimens.marginL),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    TextNum: {
        fontWeight: 'bold',
        fontSize: Dimens.textXL,
        color: Colors.textBDark,
    },
    TextStageNum: {
        fontWeight: 'bold',
        fontSize: Dimens.textXL,
        // color: Colors.textBDark,
    },
    TextTitle: {
        fontWeight: 'bold',
        fontSize: Dimens.textM,
        color: Colors.textBDark,
    },
    TextDesc: {
        fontSize: Dimens.textS,
        color: Colors.textBLight,
    },
    TextStatistics: {
        fontWeight: 'bold',
        fontSize: Dimens.textM,
    },
    loadingLayout: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingText: {
        color: Colors.textBLight,
        fontSize: Dimens.textM,
        fontWeight: 'bold',
        marginTop: `${Dimens.marginM}px`,
    }
});

const SvgIconButton = styled(IconButton)`
    && {
        align-self: center;
        padding: ${Dimens.historyChartHeaderBarIconBtnPadding}px;
    }
    &:focus {
        outline: none;
    }
`;

const SvgWrapper = styled.div`
    display: flex;
    padding-left: 10px;
    padding-right: 3px;
  & path {
    fill: ${props => props.color};
  }
`;

const ArrowIconHour = ({ positive = true, hour, color }) => {
    let rot = 0;
    if (positive === false) rot = 180;
    return (
        <>
            <SvgWrapper positive={positive} color={color}>
                <IcArrow transform={`rotate(${rot})`} style={{ width: `0.85rem`, height: `0.85rem` }} alt='IcArrow' />
            </SvgWrapper>
            <span style={{ color: color, fontSize: Dimens.textM, fontWeight: 'bold' }}>{hour}hr</span>
        </>
    );
}

const dayMinusWeek = (weekHour, weekMin, dayMin) => {
    const _w = weekHour * 60 + weekMin;
    const _t = dayMin;
    let _sub = _t - _w;
    let positive = true;
    let s_hour = '';
    let color = 'gray';
    const displayColor = (num) => {
        if (num < 1) {
            return 'gray'
        } else if (num < 3) {
            return '#ffaa00'
        } else {
            return Colors.textLower
        }
    }
    if (_sub > 0) {
        positive = true;
        _sub = _sub / 60;
        color = displayColor(_sub);
        s_hour = _sub.toFixed(1);
    } else {
        positive = false;
        _sub = (_sub * -1) / 60;
        color = displayColor(_sub);
        s_hour = _sub.toFixed(1);
    }

    return {
        positive: positive,
        color: color,
        hour: s_hour
    }
}