import * as Rx from 'rxjs';
import { Auth, API } from 'aws-amplify';

import * as ApiGateway from '../api_gateway';

import Pad from '../../data/pad';
import { NotificationData } from '../../data/pad_notification';
import moment from 'moment';

import intl from 'react-intl-universal'
import * as Colors from '../../../styles/colors';

export function padsInfo() {
    return Rx.Observable.create(async subscriber => {
        try {
            const session = await Auth.currentSession();
            const userId = session.getIdToken().decodePayload().sub;
            const params = {
                body: { 'userId': userId },
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const items = await API.post(ApiGateway.HOST, ApiGateway.DATA_PADS, params);
            console.log(items);
            let pads = [];
            for (const key in items) {
                const item = items[key];
                pads.push(new Pad(item['deviceId'], item['firmwareVer'], item['online'], item['sensors'], item['event'], item['activity'], 0, item['battery'], item['miTemp']));
            }
            subscriber.next(pads);
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}

export function notifications() {
    return Rx.Observable.create(async subscriber => {
        try {
            const session = await Auth.currentSession();
            const userId = session.getIdToken().decodePayload().sub;
            const params = {
                body: { 'userId': userId },
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const items = await API.post(ApiGateway.HOST, ApiGateway.DATA_NOTIFICATIONS, params);
            let notifications = [];
            for (const index in items) {
                const item = items[index];
                notifications.push(new NotificationData(item.deviceId, item.name, item.type, item.event, item.epoch, item.epoch));
            }
            notifications.sort(function (a, b) {
                return a.epoch - b.epoch
            })
            subscriber.next(notifications);
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}

export function notificationsRecord() {
    return Rx.Observable.create(async subscriber => {
        try {
            const session = await Auth.currentSession();
            const userId = session.getIdToken().decodePayload().sub;
            const params = {
                headers: { 'Content-Type': 'application/json', },
                response: false,
                queryStringParameters: {
                    userId: userId,
                }
            }
            const items = await API.get(ApiGateway.HOST, ApiGateway.DATA_NOTIFICATIONS_RECORD, params);
            let notifications = [];
            for (const index in items) {
                const item = items[index];
                notifications.push(new NotificationData(item.deviceId, item.name, item.type, item.event, item.epoch, item.epoch));
            }
            notifications.sort(function (a, b) {
                return a.epoch - b.epoch
            })
            subscriber.next(notifications);
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    })
}

export function notificationsHistory(deviceId, date) {
    return Rx.Observable.create(async subscriber => {
        try {
            const session = await Auth.currentSession();
            const userId = session.getIdToken().decodePayload().sub;
            let params = {
                headers: { 'Content-Type': 'application/json', },
                response: false,
                queryStringParameters: {
                    userId: userId,
                    deviceId: deviceId,
                    date: date,
                    timezone: (0 - (new Date()).getTimezoneOffset()) / 60,
                }
            }
            const data = await API.get(ApiGateway.HOST, ApiGateway.DATA_NOTIFICATIONS_HISTORY, params)
            const localEpoch = (new Date()).getTime()
            const notifications = data.map(function (item, index) {
                return new NotificationData(item.deviceId, item.name, item.type, item.event, item.epoch, localEpoch)
            });
            notifications.sort((a, b) => {
                return b.epoch - a.epoch;
            });
            subscriber.next(notifications)
            subscriber.complete()
        }
        catch (e) {
            subscriber.error(e)
        }
    })
}

export function postureHistory(deviceId, date) {
    return Rx.Observable.create(async subscriber => {
        try {
            const session = await Auth.currentSession();
            const userId = session.getIdToken().decodePayload().sub;
            let params = {
                headers: { 'Content-Type': 'application/json', },
                response: false,
                queryStringParameters: {
                    userId: userId,
                    deviceId: deviceId,
                    date: date,
                    timezone: (0 - (new Date()).getTimezoneOffset()) / 60,
                }
            }
            const data = await API.get(ApiGateway.HOST, ApiGateway.DATA_PADS_STAGE_HISTORY, params);
            const postureData = data.stages;
            const percentages = data.percentages;
            console.log(`pads stage history => deviceId: ${deviceId}, date: ${date}`)
            if (isToday(date)) {
                const d = new Date()
                postureData.push({
                    second: d.getHours() * 60 + d.getMinutes(),
                    posture: 0
                })
            }
            // const postureData = [
            //     {
            //         second: 480,
            //         posture: 1,
            //     },
            //     {
            //         second: 630,
            //         posture: 2,
            //     },
            //     {
            //         second: 1200,
            //         posture: 0,
            //     },
            //     {
            //         second: 1260,
            //         posture: 2,
            //     },
            //     {
            //         second: 360,
            //         posture: 1,
            //     },
            // ];

            let durations = [];
            let datas = [];
            let weights = [];
            let colors = [];
            let labels = [];
            for (let i = 0; i < postureData.length; i++) {
                const pd = postureData[i];
                let b = (i + 1) < postureData.length ? postureData[i + 1].second : 480;
                let a = pd.second;
                let duration = b - a > 0 ? b - a : 1440 - a + b;
                let label = {
                    from: a,
                    to: b,
                };
                durations.push(duration);
                weights.push(duration / 1440);
                if (pd.posture === 0) {
                    label['state'] = intl.get('page_history_chart_posture_message_none')
                    colors.push(Colors.primary)
                    datas.push(0)
                }
                else if (pd.posture === 1) {
                    label['state'] = intl.get('page_history_chart_posture_message_offline')
                    colors.push(Colors.primary)
                    datas.push(0.9)
                }
                else if (pd.posture === 2) {
                    label['state'] = intl.get('page_history_chart_posture_message_off_bed')
                    colors.push(Colors.historyPostureLeave)
                    datas.push(1.5)
                }
                else {
                    label['state'] = intl.get('page_history_chart_posture_message_on_bed')
                    colors.push(Colors.historyPostureLie)
                    datas.push(3)
                }
                labels.push(label)
            }
            subscriber.next({
                graphicData: { data: datas, color: colors, weight: weights, label: labels },
                percentagesData: percentages,
            });
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}

export function stageCkuster(deviceId, date) {
    return Rx.Observable.create(async subscriber => {
        try {
            const session = await Auth.currentSession();
            const userId = session.getIdToken().decodePayload().sub;
            let params = {
                headers: { 'Content-Type': 'application/json', },
                response: false,
                queryStringParameters: {
                    userId: userId,
                    deviceId: deviceId,
                    date: date,
                    timezone: (0 - (new Date()).getTimezoneOffset()) / 60,
                }
            }
            const data = await API.get(ApiGateway.HOST, ApiGateway.DATA_PADS_STAGE_CLUSTER, params)
   
            subscriber.next(data.predict);
            subscriber.complete();
        }
        catch (e) {
            subscriber.error(e);
        }
    })
}

export const weekPostureHistory = (deviceId, date) => {
    return Rx.Observable.create(async subscriber => {
        try {
            const session = await Auth.currentSession();
            const userId = session.getIdToken().decodePayload().sub;
            let params = {
                headers: { 'Content-Type': 'application/json', },
                response: false,
                queryStringParameters: {
                    userId: userId,
                    deviceId: deviceId,
                    date: date,
                    timezone: (0 - (new Date()).getTimezoneOffset()) / 60,
                }
            }
            const data = await API.get(ApiGateway.HOST, ApiGateway.DATA_PADS_WEEK_STAGE_HISTORY, params);
            console.log('week', data);

            let datas = [];
            let colors = [];
            let weights = [];
            let labels = [];
            let points = 0;
            let checkSum = 0;
            const days = data.range.day;
            for (let i = 0; i < 144; i++) {
                const weekStage = data.weekStage[i];
                const average = ((weekStage / days) * 100).toFixed(1);
                weights.push(1);
                datas.push(average);
                colors.push(postureChartColor(average));
                // 生活指標分數計算
                if (average >= 70 || average < 30) {
                    points++;
                }
                checkSum += parseInt(average);
                // colors.push(average <= 50 ? Colors.historyPostureLeave : Colors.historyPostureLie);
                labels.push({
                    from: (480 + i * 10) % 1440,
                    to: (480 + (i + 1) * 10) % 1440,
                    weekStage: average,
                });
            }
            // at least 1/4
            points = checkSum > 3600 ? Math.round((points / 144) * 100) : 0;
            const displayChart = data.display === undefined ? false : data.display;
            subscriber.next({ data: datas, color: colors, weight: weights, label: labels, displayChart: displayChart, correlation: data.correlation, statistic: data.statistic, range: getRange(data.range), points: points });
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}
const postureChartColor = (average) => {
    if (average >= 70) {
        return Colors.historyWeekHigh;
    } else if (average < 30) {
        return Colors.historyWeekLow;
    } else {
        return Colors.historyWeekMid;
    }
}
const getRange = (range) => {
    const day = range.day - 1;
    const start = range.from;
    const end = start + (day * 24 * 3600 * 1000);
    return {
        from: moment(start).format('YYYY/MM/DD'),
        to: moment(end).format('YYYY/MM/DD')
    }
}

export function activityHistory(deviceId, date) {
    return Rx.Observable.create(async subscriber => {
        try {
            const session = await Auth.currentSession();
            const userId = session.getIdToken().decodePayload().sub;
            let params = {
                headers: { 'Content-Type': 'application/json', },
                response: false,
                queryStringParameters: {
                    userId: userId,
                    deviceId: deviceId,
                    date: date,
                    timezone: (0 - (new Date()).getTimezoneOffset()) / 60,
                }
            }
            const activities = await API.get(ApiGateway.HOST, ApiGateway.DATA_PADS_ACTIVITY_HISTORY, params)
            console.log(`pads activity history => deviceId: ${deviceId}, date: ${date}`)

            let datas = [];
            let colors = [];
            let weights = [];
            let labels = [];
            for (let i = 0; i < 144; i++) {
                // const activity = Math.floor(Math.random() * 51);
                const activity = activities[i]
                weights.push(1);
                datas.push(activity);
                colors.push(i % 2 === 0 ? Colors.historyActivityLight : Colors.historyActivityDark);
                labels.push({
                    from: (480 + i * 10) % 1440,
                    to: (480 + (i + 1) * 10) % 1440,
                    activity: activity,
                });
            }
            subscriber.next({ data: datas, color: colors, weight: weights, label: labels });
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}

export function sleepHistory(deviceId, date) {
    return Rx.Observable.create(async subscriber => {
        try {
            const session = await Auth.currentSession();
            const userId = session.getIdToken().decodePayload().sub;
            // await new Promise(function (resolve, reject) {
            //     setTimeout(function () { resolve(); }, 1000);
            // });
            // const sleepData = [
            //     { second: 480, sleep: 3, },
            //     { second: 500, sleep: 2, },
            //     { second: 510, sleep: 3 },
            //     { second: 590, sleep: 2 },
            //     { second: 610, sleep: 1 },
            //     { second: 630, sleep: 0, },
            //     { second: 1260, sleep: 1, },
            //     { second: 1290, sleep: 2 },
            //     { second: 1320, sleep: 3, },
            //     { second: 1350, sleep: 2, },
            //     { second: 1360, sleep: 3, },
            // ];
            let params = {
                headers: { 'Content-Type': 'application/json' },
                response: false,
                queryStringParameters: {
                    userId: userId,
                    deviceId: deviceId,
                    date: date,
                    timezone: (0 - (new Date()).getTimezoneOffset()) / 60,
                }
            }
            const data = await API.get(ApiGateway.HOST, ApiGateway.DATA_PADS_SLEEP_HISTORY, params);
            const sleepData = data.stages;
            
            if (isToday(date)) {
                const d = new Date()
                sleepData.push({
                    second: d.getHours() * 60 + d.getMinutes(),
                    sleep: -1
                })
            }

            let durations = [];
            let datas = [];
            let weights = [];
            let colors = [];
            let labels = [];
            for (let i = 0; i < sleepData.length; i++) {
                const sd = sleepData[i];
                let b = (i + 1) < sleepData.length ? sleepData[i + 1].second : 480;
                let a = sd.second;
                let duration = b - a > 0 ? b - a : 1440 - a + b;
                let label = {
                    from: a,
                    to: b,
                };
                durations.push(duration);
                weights.push(duration / 1440);

                if (sd.sleep === -1) {
                    label['state'] = intl.get('page_history_chart_sleep_legend_awake');
                    colors.push(Colors.historySleepAwake);
                    datas.push(0);
                }
                else if (sd.sleep === 0) {
                    label['state'] = intl.get('page_history_chart_sleep_legend_awake');
                    colors.push(Colors.historySleepAwake);
                    datas.push(1.5);
                }
                else if (sd.sleep === 1) {
                    label['state'] = intl.get('page_history_chart_sleep_legend_light');
                    colors.push(Colors.historySleepLight);
                    datas.push(3);
                }
                else if (sd.sleep === 2) {
                    label['state'] = intl.get('page_history_chart_sleep_legend_none')
                    colors.push(Colors.historySleepLeave);
                    datas.push(0.9);
                }
                else {
                    label['state'] = intl.get('page_history_chart_sleep_legend_deep');
                    colors.push(Colors.historySleepDeep);
                    datas.push(3);
                }
                labels.push(label);
            }
            subscriber.next({
                graphicData: { data: datas, color: colors, weight: weights, label: labels },
                percentagesData: data.percentages
            });
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}

export function isToday(dateStr) {
    const d = new Date()
    let today = d
    if (d.getHours() >= 8) {
        today = d
    } else {
        const newD = moment(d).add(-1, 'days')
        today = newD.toDate()
    }
    return dateStr === moment(today).format('YYYY/MM/DD')
}