import * as Rx from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';

import * as SettingsApi from '../../model/api/settings_api';
import * as PadApi from '../../model/api/pad_api';

class HistoryBloc {

    get today() { return this._today }
    get btnClicked() { return this._btnClicked; }
    get datePickerDialog() { return this._datePickerDialog; }

    get configLoading() { return this._configLoading; }
    get historyLoading() { return this._historyLoading; }
    get configs() { return this._configs; }
    get selectedData() { return this._selectedData; }

    get chartData() { return this._chartData; }
    get postureChartLabel() { return this._postureChartLabel; }
    get activityChartLabel() { return this._activityChartLabel; }
    get sleepChartLabel() { return this._sleepChartLabel; }


    init() {
        console.log(`history bloc init`);
        this._disposables = new Rx.Subscription();

        const d = new Date()
        if (d.getHours() >= 8) {
            this._today = d
        } else {
            const newD = moment(d).add(-1, 'days')
            this._today = newD.toDate()
        }

        // 如果不是跳轉 預設顯示第0項
        this._index = 0;
        this._btnClicked = new Rx.Subject();
        this._datePickerDialog = new Rx.BehaviorSubject({ show: false, date: this._today });

        this._configLoading = new Rx.BehaviorSubject(false);
        this._historyLoading = new Rx.BehaviorSubject(true);
        this._configs = new Rx.BehaviorSubject([]);
        this._selectedData = new Rx.BehaviorSubject({});

        this._chartData = new Rx.BehaviorSubject({ loading: false, data: {} });
        this._postureChartLabel = new Rx.BehaviorSubject({});
        this._activityChartLabel = new Rx.BehaviorSubject({});
        this._sleepChartLabel = new Rx.BehaviorSubject({});

        this._setBtnClickedObserver();
        this._setSelectedDataObserver();
        this._getPadConfigs();
    }

    dispose() {
        this._disposables.unsubscribe();
    }

    btnDevicesClicked(index) {
        if (this._selectedData.getValue().index !== index)
            this._selectedData.next({ index: index, date: this._today });
    }

    _setBtnClickedObserver() {
        let it = this._btnClicked
            .pipe(debounceTime(300))
            .subscribe({
                next: id => {
                    console.log(`button clicked id: ${id}`);
                    switch (id) {
                        case HistoryPageId.BtnDateForward: {
                            const { index, date } = this._selectedData.getValue();
                            const newMoment = moment(date).add(1, 'days');
                            if (newMoment.isAfter(moment(this._today))) return;
                            this._selectedData.next({ index: index, date: newMoment.toDate() });
                        } break;
                        case HistoryPageId.BtnDateBackward: {
                            const { index, date } = this._selectedData.getValue();
                            const newDate = moment(date).add(-1, 'days').toDate();
                            this._selectedData.next({ index: index, date: newDate });
                        } break;
                        case HistoryPageId.BtnDatePicker:
                            this.datePickerDialog.next({ show: true, date: this._selectedData.getValue().date });
                            break;
                        default:
                            break;
                    }
                },
                error: err => {

                },
                complete: () => { }
            });
        this._disposables.add(it);
    }

    _setSelectedDataObserver() {
        let it = this.selectedData.subscribe({
            next: (data) => {
                console.log('data', data)
                if (!data.hasOwnProperty('index')) return;
                const { index, date } = data;
                console.log(`selected observer -> index: ${index}, date: ${date}`);
                const configs = this._configs.getValue()
                if (configs.length === 0) return
                this._getHistory(this._configs.getValue()[index].deviceId, date);
            },
            error: (err) => {

            },
            complete: () => {

            }
        });
        this._disposables.add(it);
    }

    _getPadConfigs() {
        this._configLoading.next(true);
        let it = SettingsApi.getConfigs()
            .subscribe({
                next: configs => {
                    console.log('configs', configs);
                    this._configs.next(configs);
                    this._selectedData.next({ index: this._index, date: this._today });
                },
                error: err => {
                    this._configLoading.next(false);
                },
                complete: () => {
                    this._configLoading.next(false);
                }
            });
        this._disposables.add(it);
    }

    _getHistory(deviceId, date) {
        this._chartData.next({ lodaing: true, data: {} });
        this._historyLoading.next(true);
        const dateStr = moment(date).format('YYYY/MM/DD');
        if (process.env.REACT_APP_LOCAL === 'true') {
            let it = Rx.zip(PadApi.postureHistory(deviceId, dateStr), PadApi.activityHistory(deviceId, dateStr), PadApi.sleepHistory(deviceId, dateStr), PadApi.weekPostureHistory(deviceId, dateStr))
                .subscribe({
                    next: (val) => {
                        console.log('history', val);
                        this._chartData.next({ loading: false, data: { statistics: { stageTime: val[0].percentagesData, sleepTime: val[2].percentagesData }, posture: val[0].graphicData, activity: val[1], sleep: val[2].graphicData, weekPosture: val[3] } });
                    },
                    error: (err) => {
                        this._chartData.next({ loading: false, data: {} });
                        this._historyLoading.next(false);
                    },
                    complete: () => {
                        this._historyLoading.next(false);
                    }
                });
            this._disposables.add(it);
        } else {
            let it = Rx.zip(PadApi.postureHistory(deviceId, dateStr), PadApi.activityHistory(deviceId, dateStr), PadApi.sleepHistory(deviceId, dateStr), PadApi.weekPostureHistory(deviceId, dateStr), PadApi.stageCkuster(deviceId, dateStr))
                .subscribe({
                    next: (val) => {
                        // console.log('history', val);
                        this._chartData.next({ loading: false, data: { statistics: { stageTime: val[0].percentagesData, sleepTime: val[2].percentagesData }, posture: val[0].graphicData, activity: val[1], sleep: val[2].graphicData, weekPosture: val[3], cluster: val[4] }});
                    },
                    error: (err) => {
                        this._chartData.next({ loading: false, data: {} });
                        this._historyLoading.next(false);
                    },
                    complete: () => {
                        this._historyLoading.next(false);
                    }
                });
            this._disposables.add(it);
        }

    }

    findIndex(deviceId) {
        this._configs.subscribe({
            next: configs => {
                if (configs.length) {
                    // console.log('findindex',configs)
                    configs.map((item, index) => {
                        if (item.deviceId === deviceId) {
                            return this._index = index;
                        }
                    })
                }
            }
        });
    }
}

class HistoryPageId {

    static get BtnDateForward() { return 'btn_date_forward'; }
    static get BtnDateBackward() { return 'btn_date_backward'; }
    static get BtnDatePicker() { return 'btn_date_picker'; }

}

export { HistoryBloc as default, HistoryPageId };